import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import history from 'helpers/history';

import Application from 'App';
import reportWebVitals from 'reportWebVitals';

ReactDOM.render(
  <div>
    <Router history={history}>
      <Application />
    </Router>
  </div>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
